@import '~antd/es/style/themes/default.less';
@pro-layout-grid-content-prefix-cls: ~'@{ant-prefix}-pro-grid-content';

.@{pro-layout-grid-content-prefix-cls} {
  width: 100%;
  &.wide {
    max-width: 1200px;
    margin: 0 auto;
  }
}

@primary-color: #D21919;@link-color: #D21919;@layout-body-background: #E3E7ED;@layout-sider-background: #23282D;@menu-dark-bg: #23282D;@menu-dark-submenu-bg: rgba(0, 0, 0, 0.45);@menu-dark-item-active-bg: fade(@primary-color, 15%);