@import '~antd/es/style/themes/default.less';

@basicLayout-prefix-cls: ~'@{ant-prefix}-pro-basicLayout';
@pro-layout-header-height: 48px;

.@{basicLayout-prefix-cls} {
  // BFC
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .@{ant-prefix}-layout-header {
    &.@{ant-prefix}-pro-fixed-header {
      position: fixed;
      top: 0;
    }
  }

  &-content {
    position: relative;
    margin: 24px;

    .@{ant-prefix}-pro-page-container {
      margin: -24px -24px 0;
    }

    &-disable-margin {
      margin: 0;

      .@{ant-prefix}-pro-page-container {
        margin: 0;
      }
    }
    > .@{ant-prefix}-layout {
      max-height: 100%;
    }
  }

  // children should support fixed
  .@{basicLayout-prefix-cls}-is-children.@{basicLayout-prefix-cls}-fix-siderbar {
    height: 100vh;
    overflow: hidden;
    transform: rotate(0);
  }

  .@{basicLayout-prefix-cls}-has-header {
    // tech-page-container
    .tech-page-container {
      height: calc(100vh - @pro-layout-header-height);
    }
    .@{basicLayout-prefix-cls}-is-children.@{basicLayout-prefix-cls}-has-header {
      .tech-page-container {
        height: calc(100vh - @pro-layout-header-height - @pro-layout-header-height;);
      }
      .@{basicLayout-prefix-cls}-is-children {
        min-height: calc(100vh - @pro-layout-header-height);
        &.@{basicLayout-prefix-cls}-fix-siderbar {
          height: calc(100vh - @pro-layout-header-height);
        }
      }
    }
  }
}
